import React, { useCallback, useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { ToastProvider } from "react-toast-notifications";
import { v4 as uuidv4 } from "uuid";
import { ThemeProvider } from "@mui/material";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "tailwindcss/tailwind.css";
import { DEFAULT_STATE, useAppStatePersist } from "lib/appState";
import { getProfileApi, updateProfileApi } from "api/auth.api";
import { useSetupExperiments } from "lib/experiments";

import theme from "../lib/theme";

import "../styles/global.css";
import { AppStateContext, SetAppStateContext } from "lib/marvyAppState";
import { setUpInterceptors } from "lib/axios";
import moment from "moment";
import { emitGTMEvent } from "lib/events";

export default function App(props) {
  const router = useRouter();
  const { Component, pageProps } = props;
  const [marvyAppState, setMarvyAppState] = useState({});
  const [instanceId, setInstanceId] = useState();
  const [loadingProfile, setLoadingProfile] = useState(false);

  const [ready, setReady] = useState(false);
  useAppStatePersist(marvyAppState);

  useEffect(() => {
    if (marvyAppState.user) {
      global.utlusr = marvyAppState.user;
      global.dataLayer.push({ event: "userInfoUpdated" });
    }
  }, [marvyAppState]);

  useSetupExperiments();
  useEffect(() => {
    const browser = typeof window !== "undefined";
    setMarvyAppState({
      ...(browser
        ? JSON.parse(global.localStorage.getItem("appState")) || {}
        : DEFAULT_STATE),
      __initialized: true,
    });
    setInstanceId(uuidv4());
  }, []);

  const getAppState = useCallback(() => marvyAppState, [marvyAppState]);

  useEffect(() => {
    const unset = setUpInterceptors(getAppState, setMarvyAppState);
    setReady(true);
    return () => {
      setReady(false);
      unset();
    };
  }, [getAppState]);

  useEffect(() => {
    console.log({ Component, pageProps });
  }, [Component, pageProps]);

  useEffect(() => {
    const q = router.query;
    if (
      !localStorage.getItem("uptimeline_utmData") &&
      [
        q["utm_source"],
        q["utm_medium"],
        q["utm_campaign"],
        q["utm_id"],
        q["utm_term"],
        q["utm_content"],
        q["gclid"],
      ].find((x) => !!x)
    ) {
      localStorage.setItem(
        "uptimeline_utmData",
        JSON.stringify({
          utm_source: q["utm_source"],
          utm_medium: q["utm_medium"],
          utm_campaign: q["utm_campaign"],
          utm_id: q["utm_id"],
          utm_term: q["utm_term"],
          utm_content: q["utm_content"],
          gclid: q["gclid"],
        })
      );
    }
  }, [router.query]);
  useEffect(() => {
    async function getProfile() {
      console.log("refreshing profile!");
      setLoadingProfile(true);
      const res = await getProfileApi();
      if (
        res.data.user?.tags?.firstMonitorCreationDate &&
        res.data.user?.tags?.returnedAfter1Week !== "true"
      ) {
        const firstMonitorCreationDate = moment(
          res.data.user?.tags?.firstMonitorCreationDate
        );
        if (moment().diff(firstMonitorCreationDate, "days") > 7) {
          await emitGTMEvent({
            event: "track",
            eventName: "returnedAfter1Week",
          });
          await updateProfileApi({
            tags: {
              ...(res.data.user?.tags || {}),
              returnedAfter1Week: "true",
            },
          });
        }
      }
      setMarvyAppState({
        ...marvyAppState,
        user: res.data.user,
        subscriptions: res.data.subscriptions,
        instanceId,
      });
    }
    if (
      !loadingProfile &&
      marvyAppState.instanceId !== instanceId &&
      marvyAppState.tokens
    ) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marvyAppState, setMarvyAppState, instanceId, loadingProfile]);

  return (
    <AppStateContext.Provider value={marvyAppState}>
      <SetAppStateContext.Provider
        value={(...props) => {
          console.log("setting marvy app state", ...props);
          setMarvyAppState(...props);
        }}
      >
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <React.Fragment>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
                <title>
                  Uptimeline, alerting you when your websites go down.
                </title>
              </Head>
              {ready ? <Component {...pageProps} /> : null}
            </React.Fragment>
          </ToastProvider>
        </ThemeProvider>
      </SetAppStateContext.Provider>
    </AppStateContext.Provider>
  );
}
